<template>
  <div v-if="inited">
    <template v-for="(item, index) in notifications">
      <v-layout
        :key="index"
        :style="{
          'background-color':
            notificationListId && index < firstWhite
              ? 'white'
              : 'rgb(239,239,239)',
        }"
      >
        <v-flex xs5 class="made-by">
          <div>
            {{
              item.made_by
                ? item.made_by.firstName + " " + item.made_by.lastName
                : "-"
            }}
          </div>
          <div>{{ item.date }} at {{ new Date(item.created_at).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) }}</div>
        </v-flex>
        <v-flex xs7>
          <v-card-title primary-title>
            <div>
              <div class="text-medium">
                {{ item.title }} {{ item.order ? "#" + item.order.id : "" }}
              </div>
              <div v-if="item.subOrder" class="text-medium text-gray ">
                <span
                  v-if="item.subOrder"
                  v-luxon:date_med="{
                    value: item.subOrder.date + 'T12:00:00',
                  }"
                />
              </div>
              <div class="text-medium" v-html="item.message"></div>
            </div>
          </v-card-title>
        </v-flex>
      </v-layout>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    notifications: {
      type: Array,
      required: true,
    },
    notificationListId: {
      type: String,
    },
  },
  data: () => ({
    firstWhite: 0,
    inited: false,
  }),
  mounted() {
    this.$nextTick(() => {
      this.inited = true;
    });
  },
  watch: {
    notifications() {
      if (this.notificationListId) {
        var lastNotificationsCount = localStorage.getItem(
          `notificationsList${this.notificationListId}`
        );
        // console.log(
        //   "lastNotificationsCount",
        //   lastNotificationsCount,
        //   "notifications",
        //   this.notifications
        // );
        if (!lastNotificationsCount)
          this.firstWhite = this.notifications.length;
        else
          this.firstWhite = this.notifications.length - lastNotificationsCount;
      }
      this.inited = true;
    },
  },
  beforeDestroy() {
    localStorage.setItem(
      `notificationsList${this.notificationListId}`,
      this.notifications ? this.notifications.length : 0
    );
  },
};
</script>

<style lang="scss" scoped>
.made-by {
  text-align: center;
  margin: auto;
}
.text-medium {
  font-size: medium;
  max-width: 400px;
}
.text-gray {
  color: gray;
}
</style>
