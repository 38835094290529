var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.inited)?_c('div',[_vm._l((_vm.notifications),function(item,index){return [_c('v-layout',{key:index,style:({
        'background-color':
          _vm.notificationListId && index < _vm.firstWhite
            ? 'white'
            : 'rgb(239,239,239)',
      })},[_c('v-flex',{staticClass:"made-by",attrs:{"xs5":""}},[_c('div',[_vm._v(" "+_vm._s(item.made_by ? item.made_by.firstName + " " + item.made_by.lastName : "-")+" ")]),_c('div',[_vm._v(_vm._s(item.date)+" at "+_vm._s(new Date(item.created_at).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })))])]),_c('v-flex',{attrs:{"xs7":""}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',[_c('div',{staticClass:"text-medium"},[_vm._v(" "+_vm._s(item.title)+" "+_vm._s(item.order ? "#" + item.order.id : "")+" ")]),(item.subOrder)?_c('div',{staticClass:"text-medium text-gray"},[(item.subOrder)?_c('span',{directives:[{name:"luxon",rawName:"v-luxon:date_med",value:({
                  value: item.subOrder.date + 'T12:00:00',
                }),expression:"{\n                  value: item.subOrder.date + 'T12:00:00',\n                }",arg:"date_med"}]}):_vm._e()]):_vm._e(),_c('div',{staticClass:"text-medium",domProps:{"innerHTML":_vm._s(item.message)}})])])],1)],1)]})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }