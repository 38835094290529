<template>
  <!-- ----------------------------------------------------------------------------- -->
  <!-- Events -->
  <!-- ----------------------------------------------------------------------------- -->
  <!-- <div>
    <v-list-item-subtitle class="text-wrap">
      This is an example of a planner with additional event handlers and
      external components controlling the display of the calendar.
  </v-list-item-subtitle>-->
  <div v-if="ordersLoaded">
    <v-card-title>
      Calendar
      <v-spacer></v-spacer>
      <v-checkbox
        class="filter-checkbox mr-4"
        value
        v-model="onlyMyOrders"
        v-if="isAdmin || isClientAdmin || isClientUser"
        label="Only My Orders"
      ></v-checkbox>
      <v-select
        class="filter-input mr-2"
        single-line
        v-if="isAdmin || isClientUser || isClientAdmin || isDriver"
        hide-details
        :items="
          [
            {
              text: 'All Orders',
              value: null,
            },
          ].concat(
            orderTypes.map((item) => {
              return {
                text: item.name,
                value: parseInt(item.value),
              };
            })
          )
        "
        v-model="orderTypeSelected"
        label="All Orders"
      ></v-select>
      <v-select
        class="filter-input mr-2"
        single-line
        hide-details
        v-if="isAdmin"
        :items="
          !customers
            ? [
                {
                  text: 'All Clients',
                  value: null,
                },
              ]
            : [
                {
                  text: 'All Clients',
                  value: null,
                },
              ].concat(
                customers.map((item) => {
                  return {
                    text: item.name,
                    value: parseInt(item.id),
                  };
                })
              )
        "
        v-model="customer"
        label="All Clients"
      ></v-select>
      <v-select
        class="filter-input mr-2"
        single-line
        hide-details
        v-if="isAdmin || isClientUser || isClientAdmin"
        :items="
          [
            {
              text: 'All Passengers',
              value: null,
            },
          ].concat(
            patients
              .filter((item) => {
                if (item !== null) {
                  if (customer) {
                    if (item.customer.id == customer) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                }
              })
              .map((item) => {
                return {
                  text: item.firstName + ' ' + item.lastName,
                  value: parseInt(item.id),
                };
              })
          )
        "
        v-model="patient"
        label="All Passengers"
      ></v-select>
      <v-select
        class="filter-input mr-2"
        single-line
        v-if="isAdmin"
        hide-details
        :items="
          !drivers
            ? [
                {
                  text: 'All Drivers',
                  value: null,
                },
                {
                  text: 'Not Assigned',
                  value: -1,
                },
              ]
            : [
                {
                  text: 'All Drivers',
                  value: null,
                },
                {
                  text: 'Not Assigned',
                  value: -1,
                },
              ].concat(
                drivers.map((item) => {
                  return {
                    text: item.firstName + ' ' + item.lastName,
                    value: parseInt(item.id),
                  };
                })
              )
        "
        v-model="driver"
        label="All Drivers"
      ></v-select>
    </v-card-title>
    <v-sheet height="64">
      <v-toolbar flat color="white">
        <v-btn outlined class="mr-4" color="white darken-2" @click="setToday"
          >Today</v-btn
        >
        <v-btn fab text small color="white darken-2" @click="prev">
          <v-icon small>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn fab text small color="white darken-2" @click="next">
          <v-icon small>mdi-chevron-right</v-icon>
        </v-btn>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu bottom right>
          <template v-slot:activator="{ on }">
            <v-btn outlined color="white darken-2" v-on="on">
              <span>{{ typeToLabel[type] }}</span>
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'day'">
              <v-list-item-title>Day</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'week'">
              <v-list-item-title>Week</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'month'">
              <v-list-item-title>Month</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = '4day'">
              <v-list-item-title>4 days</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="focus"
        color="primary"
        :events="events"
        :event-color="getEventColor"
        :now="today"
        :type="type"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
        @change="updateRange"
      >
        <template #event="{ event }">
          <div class="d-flex flex-column">
            <div class="v-event-draggable" v-html="event.name"></div>
            <div>{{ event.status }}</div>
            <!-- <div
              v-if="timed"
              class="v-event-drag-bottom"
              @mousedown.stop="extendBottom(event)"
            ></div>-->
          </div>
        </template>
      </v-calendar>
      <v-dialog v-model="dialog" max-width="800px" persistent>
        <v-card>
          <v-card-title>
            <span class="headline">Edit Order</span>
          </v-card-title>

          <AddEditOrderComponent
            v-if="dialog && editedItem"
            :cancelCallback="cancel"
            :saveCallback="save"
            :editedItem="editedItem"
            :setMessageSnackbar="setMessageSnackbar"
          />
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogSubOrder" max-width="800px" persistent>
        <v-card>
          <v-card-title>
            <span class="headline"
              >Edit Appointment
              {{
                editedSubOrderItem && editedSubOrderItem.order
                  ? `(Order #${editedSubOrderItem.order})`
                  : ""
              }}
              <!-- {{
                editedSubOrderItem
                  ? editedSubOrderItem.date + " " + editedSubOrderItem.time
                  : ""
              }} -->
            </span>
          </v-card-title>
          <v-card-subtitle
            v-if="isClientAdmin || isClientUser"
            class="mt-0 body-1 black--text"
          >
            <span v-if="editedSubOrderItem && editedSubOrderItem.patient"
              >Passenger Name:
              {{
                `${editedSubOrderItem.patient.firstName} ${editedSubOrderItem.patient.lastName}`
              }}</span
            >
          </v-card-subtitle>
          <AddEditSubOrderComponent
            v-if="dialogSubOrder && editedSubOrderItem"
            :cancelCallback="cancelSubOrder"
            :saveCallback="saveSubOrder"
            :editedItem="editedSubOrderItem"
          />
        </v-card>
      </v-dialog>
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
        max-width="100%"
        style="width: 100% !important"
      >
        <v-card
          color="white lighten-4"
          min-width="350px"
          max-width="700px"
          flat
          v-if="selectedEvent && selectedEvent.data"
        >
          <v-toolbar :color="selectedEvent.color" dark>
            <!-- <v-btn icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>-->
            <v-toolbar-title>
              {{ selectedEvent.popupName }} -
              <span
                v-luxon:date_med="{
                  value: selectedEvent.startString,
                }"
              />
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div
              class="d-flex flex-column justify-space-between mr-1"
              style="height: 36px"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    style="height: 17px"
                    color="primary"
                    dark
                    @click="infoOrder(selectedEvent.data.order)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>mdi-information-variant</v-icon>
                  </v-btn>
                </template>
                <span>More info</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    style="height: 17px"
                    color="primary"
                    dark
                    @click="
                      cancelOrderItem(
                        selectedEvent.data.order,
                        selectedEvent.data.subOrder
                      )
                    "
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </template>

                <span>Cancel appointment</span>
              </v-tooltip>
            </div>
            <v-btn
              color="primary"
              dark
              class="mr-1"
              @click="editOrderItem(selectedEvent.data.order)"
              >Edit Order</v-btn
            >
            <v-btn
              @click="
                callEditSubOrderItem(
                  selectedEvent.data.order,
                  selectedEvent.data.subOrder
                )
              "
              color="primary"
              dark
              >Edit Appointment</v-btn
            >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="min-width: 25px; width: 25px"
                  class="ml-1"
                  color="primary"
                  dark
                  @click="deleteItem(selectedEvent.data.order)"
                  v-if="isAdmin"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete Order</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text></v-card-text>
          <v-card-text class="selected-event-text">
            <span class="selected-event-text__title mb-5">Status</span>
            <span class="selected-event__description">
              <span
                v-if="
                  !selectedEvent.editStatus || selectedEvent.editStatus == false
                "
                >{{ selectedEvent.status }}</span
              >
              <!-- <v-icon
                small
                class="ml-2 mr-2"
                @click="selectedEvent.editStatus = true"
                v-if="
                  !selectedEvent.editStatus || selectedEvent.editStatus == false
                "
                >mdi-pencil</v-icon
              > -->
              <v-chip
                color="primary"
                v-if="selectedEvent.editStatus == true"
                @click="
                  selectedEvent.editStatus = false;
                  setNextStatus(
                    selectedEvent,
                    getNextStatus(selectedEvent.status)
                  );
                "
                >{{ getNextStatus(selectedEvent.status) }}</v-chip
              >
            </span>
          </v-card-text>
          <v-card-text class="selected-event-text">
            <span class="selected-event-text__title">Start Date</span>
            <span class="selected-event__description">
              <!-- {{ selectedEvent.data.dateStart }} -->
              <span
                v-luxon:date_med="{
                  value: selectedEvent.data.dateStart + 'T12:00:00',
                }"
              />
              {{ tConvert(selectedEvent.data.time) }}
            </span>
          </v-card-text>
          <v-card-text class="selected-event-text">
            <span class="selected-event-text__title">End Date</span>
            <span class="selected-event__description">
              <!-- {{
              selectedEvent.data.dateEnd
              }}-->
              <span
                v-luxon:date_med="{
                  value: selectedEvent.data.dateEnd + 'T12:00:00',
                }"
              />
            </span>
          </v-card-text>
          <v-card-text class="selected-event-text">
            <span class="selected-event-text__title">Round Trip</span>
            <span class="selected-event__description">
              {{ selectedEvent.data.roundTrip == 0 ? "no" : "yes" }}
            </span>
          </v-card-text>
          <v-card-text class="selected-event-text">
            <span class="selected-event-text__title">Pickup Time</span>
            <span class="selected-event__description">
              {{
                selectedEvent.data.pickupTime === null
                  ? "-"
                  : selectedEvent.data.pickupTime
              }}
            </span>
          </v-card-text>
          <v-card-text class="selected-event-text">
            <span class="selected-event-text__title"></span>
            <span class="selected-event__description">
              {{ getRepeatString(selectedEvent, selectedEvent.data.repeat) }}
            </span>
          </v-card-text>
          <v-card-text></v-card-text>
          <v-card-text class="selected-event-text">
            <span class="selected-event-text__title">Passenger</span>
            <span class="selected-event__description">
              {{
                selectedEvent.data.patient
                  ? selectedEvent.data.patient.firstName
                  : "-"
              }}
              {{
                selectedEvent.data.patient
                  ? selectedEvent.data.patient.lastName
                  : "-"
              }}
            </span>
          </v-card-text>
          <v-card-text class="selected-event-text">
            <span class="selected-event-text__title">Phone Number</span>
            <span class="selected-event__description">
              {{
                selectedEvent.data.patient
                  ? selectedEvent.data.patient.phone
                  : "-"
              }}
            </span>
          </v-card-text>
          <v-card-text class="selected-event-text">
            <span class="selected-event-text__title">Pickup Location</span>
            <span class="selected-event__description">
              {{
                selectedEvent.data.pickupLocation
                  ? selectedEvent.data.pickupLocation.name +
                    " (" +
                    selectedEvent.data.pickupLocation.address +
                    (selectedEvent.data.pickupLocation.note
                      ? ", " + selectedEvent.data.pickupLocation.note
                      : "") +
                    ")"
                  : "-"
              }}
            </span>
          </v-card-text>
          <v-card-text class="selected-event-text">
            <!-- {{selectedEvent.data.destination}} -->
            <span class="selected-event-text__title">Destination</span>
            <span class="selected-event__description">
              {{
                selectedEvent.data.destination
                  ? selectedEvent.data.destination.alias +
                    " (" +
                    selectedEvent.data.destination.address +
                    (selectedEvent.data.destination.comment
                      ? ", " + selectedEvent.data.destination.comment
                      : "") +
                    ")"
                  : "-"
              }}
            </span>
          </v-card-text>
          <v-card-text></v-card-text>
          <v-card-text class="selected-event-text">
            <span class="selected-event-text__title">Note</span>
            <span class="selected-event__description">
              {{ selectedEvent.data.note }}
            </span>
          </v-card-text>
          <v-card-text class="selected-event-text">
            <span class="selected-event-text__title">Driver</span>
            <span class="selected-event__description">
              {{
                selectedEvent.data.driver
                  ? selectedEvent.data.driver.firstName +
                    " " +
                    selectedEvent.data.driver.lastName
                  : "Not yet assigned"
              }}
              {{
                selectedEvent.data.driver
                  ? ", " + selectedEvent.data.driver.phone
                  : ""
              }}
            </span>
          </v-card-text>
          <v-card-text class="selected-event-text" v-if="isAdmin">
            <span class="selected-event-text__title">Client</span>
            <span class="selected-event__description">
              {{
                selectedEvent.data.customer
                  ? selectedEvent.data.customer.name
                  : "-"
              }}
            </span>
          </v-card-text>
          <v-card-text></v-card-text>
          <v-card-text class="selected-event-text">
            <span class="selected-event-text__title">Created By</span>
            <span class="selected-event__description">
              {{
                selectedEvent.data.ordered_by
                  ? selectedEvent.data.ordered_by.firstName +
                    " " +
                    selectedEvent.data.ordered_by.lastName
                  : "-"
              }}
              {{
                selectedEvent.data.ordered_by &&
                selectedEvent.data.ordered_by.phone
                  ? ", " + selectedEvent.data.ordered_by.phone
                  : ""
              }}
            </span>
          </v-card-text>
          <v-card-text class="selected-event-text">
            <span class="selected-event-text__title">Created At</span>
            <span class="selected-event__description">
              <span
                v-luxon:date_med="{
                  value: selectedEvent.data.created_at,
                }"
              />
            </span>
          </v-card-text>
          <v-card-text v-if="canSeeDriverPrice || canSeePrice"></v-card-text>
          <v-card-text class="selected-event-text" v-if="canSeePrice">
            <span class="selected-event-text__title">Distance</span>
            <span class="selected-event__description">
              {{ selectedEvent.data.distance }}
              {{ selectedEvent.data.distance > 1 ? "Miles" : "Mile" }}
            </span>
          </v-card-text>
          <v-card-text class="selected-event-text" v-if="canSeePrice">
            <span class="selected-event-text__title">Price</span>
            <span class="selected-event__description">
              ${{ selectedEvent.data.price }}
            </span>
          </v-card-text>
          <v-card-text class="selected-event-text" v-if="canSeeDriverPrice">
            <span class="selected-event-text__title">Driver Cost</span>
            <span class="selected-event__description">
              ${{ selectedEvent.data.priceDriver }}
            </span>
          </v-card-text>
          <v-card-actions>
            <!-- <v-btn text color="secondary" @click="selectedOpen = false">Cancel</v-btn> -->
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-sheet>

    <v-dialog v-model="notificationsModal" max-width="800px">
      <v-card>
        <NotificationsComponent
          :notifications="notificationsList"
          v-if="notificationsModal"
          :notificationListId="notificationsListId + '_' + user.id"
          :key="new Date().getTime()"
        />
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCancelOrder" max-width="800px" persistent>
      <v-card>
        <v-card-title style="word-wrap: wrap">
          <span class="headline"> {{ dialogCancelTitle }}</span>
        </v-card-title>
        <div style="max-width: 500px; margin: auto">
          <v-text-field
            v-model="dialogCancelReason"
            hide-details="auto"
            :rules="requiredRules"
            label="Cancellation Reason"
            required
            outlined
            class="mt-4"
          ></v-text-field>
        </div>
        <v-card-actions class="order-popup-actions">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogCancelOrder = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="cancelOrderItemConfirm()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="messageOnScreenModal"
      absolute
      centered
      color="primary"
      elevation="24"
      style="z-index: 20001"
    >
      {{ messageOnScreenText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary pr-5 pl-5"
          dark
          v-bind="attrs"
          @click="messageOnScreenModal = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
  <!-- </div> -->
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";

import AddEditOrderComponent from "@/components/data/AddEditOrderComponent";
import AddEditSubOrderComponent from "@/components/data/AddEditSubOrderComponent";
import NotificationsComponent from "@/components/data/NotificationsComponent";
import { convertTime12to24 } from "@/utils/datetime.js";

export default {
  name: "CalendarEvents",
  components: {
    AddEditOrderComponent,
    AddEditSubOrderComponent,
    NotificationsComponent,
  },
  data: () => ({
    dialog: false,
    dialogSubOrder: false,
    //
    editedIndex: -1,
    editedItem: {},
    editedSubOrderItem: {},
    onlyMyOrders: true,
    //
    dialogCancelOrder: false,
    dialogCancelOrderItem: null,
    dialogCancelReason: null,
    dialogCancelTitle: null,
    //
    notificationsModal: false,
    notificationsList: [],
    notificationsListId: null,
    //
    messageOnScreenModal: false,
    messageOnScreenText: "",
    //
    focus: "",
    type: "week",
    today: null,
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    start: null,
    end: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    // colors: ["info", "success", "warning", "error", "indigo", "pink"],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
    //
    repeatOptions: [
      {
        text: "None",
        value: -1,
      },
      {
        text: "Every day",
        value: 1,
      },
      {
        text: "Every weekday",
        value: 2,
      },
      {
        text: "Every week",
        value: 3,
      },
      // {
      //   text: "Every month",
      //   value: 4,
      // },
    ],
    //
    customer: null,
    driver: null,
    patient: null,
    orderTypeSelected: 1,
    //
    patients: [],
    orderTypes: [
      {
        name: "Only Active Orders",
        value: 1,
      },
    ],
    //
    ordersLoaded: false,
    orders: [],
    requiredRules: [(v) => !!v || "Field is required"],
  }),
  apollo: {
    Orders: {
      prefetch: true,
      query: require("@/graphql/OrdersWithNotifcations.gql"),
      fetchPolicy: "cache-and-network",
      pollInterval: 5000,
      update: (data) => data,
      result(data) {
        // console.log("orders updating");
        if (!data.data) return;
        try {
          const { parse, stringify } = require("flatted/cjs");
          localStorage.setItem("orders", stringify(data.data.orders));
          localStorage.setItem("customers", stringify(data.data.customers));
        } catch (err) {
          console.log("error with cache", err);
        }
        // console.log("orders", data);
        this.orders = data.data.orders;

        this.patients = this.orders.map((item) => item.patient);
        if (this.patients) {
          this.patients.sort(function (a, b) {
            if (
              a &&
              b &&
              a.firstName &&
              b.firstName &&
              a.firstName < b.firstName
            ) {
              return -1;
            }
            if (
              a &&
              b &&
              a.firstName &&
              b.firstName &&
              a.firstName > b.firstName
            ) {
              return 1;
            }
            return 0;
          });
        }

        // this.ordersLoaded = true;
        // this.$nextTick(() => {
        //   console.log("checkChange");
        //   // this.$refs.calendar.checkChange();
        //   this.$refs.calendar.next();
        //   this.$nextTick(() => {
        //     this.$refs.calendar.prev();
        //   });
        // });
      },
    },
  },
  watch: {
    orders() {
      this.$nextTick(() => {
        let start = this.start;
        let end = this.end;
        this.updateRange({ start, end });
      });
    },
    customer(item) {
      if (this.$refs.calendar) {
        // console.log(this.$refs);
        this.$refs.calendar.next();
        this.$nextTick(() => {
          this.$refs.calendar.prev();
        });
      } else {
        // console.log("calendar not defined");
      }
    },
    driver(item) {
      if (this.$refs.calendar) {
        // console.log(this.$refs);
        this.$refs.calendar.next();
        this.$nextTick(() => {
          this.$refs.calendar.prev();
        });
      } else {
        // console.log("calendar not defined");
      }
    },
    onlyMyOrders(item) {
      // console.log(this.$refs);
      if (this.$refs.calendar) {
        this.$refs.calendar.next();
        this.$nextTick(() => {
          this.$refs.calendar.prev();
        });
      } else {
        // console.log("calendar not defined");
      }
    },
    patient(item) {
      if (this.$refs.calendar) {
        // console.log(this.$refs);
        this.$refs.calendar.next();
        this.$nextTick(() => {
          this.$refs.calendar.prev();
        });
      } else {
        // console.log("calendar not defined");
      }
    },
    orderTypeSelected(item) {
      if (this.$refs.calendar) {
        this.$refs.calendar.next();
        this.$nextTick(() => {
          this.$refs.calendar.prev();
        });
      } else {
        // console.log("calendar not defined");
      }
    },
  },
  computed: {
    canSeeDriverPrice() {
      if (!this.isAdmin && !this.isDriver) {
        return false;
      }
      if (this.isDriver && !this.user.showPrice) return false;
      return true;
    },
    canSeePrice() {
      if (!this.isAdmin && !this.isClientAdmin && !this.isClientUser) {
        return false;
      }
      if (this.isClientUser || this.isClientAdmin) {
        if (!this.user.showPrice) return false;
        if (this.user.customer && !this.user.customer.showPrice) return false;
      }
      return true;
    },
    customers() {
      if (!this.Orders || !this.Orders.customers) return [];
      return this.Orders.customers;
    },
    drivers() {
      if (!this.Orders || !this.Orders.users) return [];
      return this.Orders.users;
    },
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);

      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
        case "4day":
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
        case "day":
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
    ...mapGetters([
      "user",
      "strapi",
      "isAdmin",
      "isClientAdmin",
      "isClientUser",
      "isDriver",
    ]),
  },
  mounted() {
    this.onlyMyOrders = !this.isAdmin;

    try {
      const { parse, stringify } = require("flatted/cjs");
      let ordersCached = parse(localStorage.getItem("orders"));
      let customersCached = parse(localStorage.getItem("customers"));
      if (ordersCached) {
        this.orders = ordersCached;
        this.patients = this.orders.map((item) => item.patient);
        if (this.patients) {
          this.patients.sort(function (a, b) {
            if (
              a &&
              b &&
              a.firstName &&
              b.firstName &&
              a.firstName < b.firstName
            ) {
              return -1;
            }
            if (
              a &&
              b &&
              a.firstName &&
              b.firstName &&
              a.firstName > b.firstName
            ) {
              return 1;
            }
            return 0;
          });
        }

        this.ordersLoaded = true;
        this.$nextTick(() => {
          // console.log("checkChange");
          // this.$refs.calendar.checkChange();
          this.$refs.calendar.next();
          this.$nextTick(() => {
            this.$refs.calendar.prev();
          });
        });
      }
      if (customersCached) {
        this.customers = customersCached;
      }
    } catch (err) {
      console.log("error with cache", err);
    }
    // this.orders = await this.strapi.getEntries("orders");
  },
  methods: {
    infoOrder(id) {
      const item = this.orders.filter((i) => i.id == id);
      // console.log("item", item[0]);

      if (
        !item[0] ||
        !item[0].notifications ||
        item[0].notifications.length == 0
      )
        return;

      this.notificationsList = item[0].notifications
        ? item[0].notifications.slice().reverse()
        : [];
      this.notificationsListId = item[0].id;
      this.notificationsModal = true;
    },
    async deleteItem(orderId) {
      // console.log(data.orders);
      const index = this.orders.findIndex((i) => i.id == orderId);
      var result = confirm(
        "Are you sure you want to delete order and all appointments inside it?"
      );
      if (result) {
        await this.strapi.deleteEntry("orders", orderId);
        this.orders.splice(index, 1);
      }
    },
    setMessageSnackbar(text) {
      this.messageOnScreenModal = true;
      this.messageOnScreenText = text;
    },
    cancelOrderItem(orderId, subOrderId) {
      const item = this.orders.filter((i) => i.id == orderId);
      if (item.length == 0) return;
      const subOrderItem = item[0].sub_orders.filter((i) => i.id == subOrderId);
      if (subOrderItem.length == 0) return;

      if (subOrderItem[0].status != "Pending") {
        if (this.isClientAdmin || this.isClientUser) {
          let currentDateTime = new Date(Date.now()).getTime();
          let appointmentString = `${subOrderItem[0].date}T${convertTime12to24(
            subOrderItem[0].time
          )}:00`;
          let appointmentDate = new Date(appointmentString);
          let appointmentDateMinusDay = new Date(appointmentString);
          appointmentDateMinusDay.setDate(
            appointmentDateMinusDay.getDate() - 1
          );

          if (
            currentDateTime >= appointmentDateMinusDay.getTime() &&
            currentDateTime < appointmentDate.getTime()
          ) {
            this.messageOnScreenModal = true;
            this.messageOnScreenText =
              "You are not allowed to cancel today’s confirmed appointment, please contact company representative.";

            return;
          }
        }
      }

      this.dialogCancelOrder = true;
      this.dialogCancelOrderItem = subOrderItem[0];
      this.dialogCancelReason = "";
      this.dialogCancelTitle =
        subOrderItem[0].status == "Confirmed"
          ? "Are you sure you want to cancel already confirmed appointment?"
          : "Are you sure you want to cancel this appointment?";

      //  var result = confirm(
      //     orderItem.status == "Confirmed"
      //       ? "Are you sure you want to cancel already confirmed order?"
      //       : "Are you sure you want to delete this item?"
      //   );
      //   if (result) {
      // orderItem.status = "Canceled";
      // this.strapi.updateEntry("sub-orders", parseInt(orderItem.id), {
      //   status: "Canceled",
      //   id: parseInt(orderItem.id),
      // });
      // }
      // const index = item.data.future.findIndex((i) => orderItem.date == i.date);
      // var result = confirm("Are you sure you want to delete this item?");
      // if (result) {
      //   orderItem.status = "Canceled";
      //   item.data.future.splice(index, 1);
      //   item.data.actual.push(orderItem);
      //   console.log(item.data);
      //   this.strapi.updateEntry(
      //     "orders",
      //     { id: parseInt(item.id) },
      //     { data: item.data, id: parseInt(item.id) }
      //   );
      // }
    },
    async cancelOrderItemConfirm() {
      this.dialogCancelOrderItem.status = "Canceled";
      this.dialogCancelOrderItem.cancelReason = this.dialogCancelReason;
      this.strapi.updateEntry(
        "sub-orders",
        parseInt(this.dialogCancelOrderItem.id),
        {
          status: "Canceled",
          cancelReason: this.dialogCancelReason,
          id: parseInt(this.dialogCancelOrderItem.id),
        }
      );
      this.dialogCancelOrder = false;
    },
    async save(order, type, processDates) {
      this.cancel();
      try {
        if (type === 'add') {
          var newItem = await this.strapi.createEntry("orders", order);
          processDates(newItem)
        } else {
            var updatedItem = await this.strapi.updateEntry(
              "orders",
              parseInt(order.id),
              order
            );

            processDates(updatedItem);
        }

      } catch(e) {
        console.log(e);
      } finally {
        await this.$apollo.queries.Orders.refetch();
      }
    },
    cancel() {
      this.dialog = false;
      this.$nextTick(() => {
        // Object.assign(this.editedItem, this.defaultItem);
        this.editedItem = null;
        this.editedIndex = -1;
        // this.editedItem = this.lodash.cloneDeep(this.defaultOrder);
      });
    },
    cancelSubOrder() {
      this.dialogSubOrder = false;
      this.$nextTick(() => {
        this.editedSubOrderItem = null;
      });
    },
    async saveSubOrder() {
      this.cancelSubOrder();
      this.$apollo.queries.Orders.refetch();
    },
    callEditSubOrderItem(orderId, id) {
      let editedIndex = this.orders.findIndex((i) => i.id == orderId);
      let editedItem = this.orders[editedIndex];

      if (editedItem.sub_orders && Array.isArray(editedItem.sub_orders)) {
        let subOrderIndex = editedItem.sub_orders.findIndex((i) => i.id == id);
        this.editedSubOrderItem = this.lodash.cloneDeep(
          editedItem.sub_orders[subOrderIndex]
        );
        // this.editedSubOrderItem.driver = this.editedSubOrderItem.driver
        //   ? parseInt(this.editedSubOrderItem.driver.id)
        //   : undefined;

        this.dialogSubOrder = true;
      }
    },
    editOrderItem(id) {
      this.editedIndex = this.orders.findIndex((i) => i.id == id);
      this.editedItem = this.lodash.cloneDeep(this.orders[this.editedIndex]);
      this.editedItem.customer = this.editedItem.customer
        ? parseInt(this.editedItem.customer.id)
        : undefined;
      this.editedItem.patient = this.editedItem.patient
        ? parseInt(this.editedItem.patient.id)
        : undefined;
      this.editedItem.driver = this.editedItem.driver
        ? parseInt(this.editedItem.driver.id)
        : undefined;

      this.dialog = true;
    },
    setNextStatus(event, status) {
      //TODO: finish
      // if (!status || status == "" || !this.Orders || !this.Orders.orders)
      //   return;
      // event.status = status;
      // var updatedOrder = event.data;
      // // console.log("event.data", event.data);
      // // for (let i = 0; i < this.Orders.orders.length; i++){
      // //   if (this.Orders.orders[i].id)
      // // }
      // var startRaw = event.startRaw;
      // var futureEventFound = undefined;
      // for (let i = 0; i < updatedOrder.data.future.length; i++) {
      //   if (updatedOrder.data.future[i].date == startRaw) {
      //     futureEventFound = updatedOrder.data.future[i];
      //     break;
      //   }
      // }
      // if (futureEventFound) {
      //   updatedOrder.data.future = updatedOrder.data.future.filter(
      //     (item) => item.date != startRaw
      //   );
      //   if (!updatedOrder.data.actual) updatedOrder.data.actual = [];
      //   futureEventFound.status = status;
      //   updatedOrder.data.actual.push(futureEventFound);
      // } else {
      //   for (let i = 0; i < updatedOrder.data.actual.length; i++) {
      //     if (updatedOrder.data.actual[i].date == startRaw) {
      //       updatedOrder.data.actual[i].status = status;
      //       break;
      //     }
      //   }
      // }
      // event.data = updatedOrder;
      // this.strapi.updateEntry(
      //   "orders",
      //   { id: parseInt(updatedOrder.id) },
      //   { data: updatedOrder.data, id: parseInt(updatedOrder.id) }
      // );
    },
    getNextStatus(status) {
      switch (status) {
        case "Pending":
          return "Passenger Contacted";
        case "Passenger Contacted":
          return "In Progress";
        case "In Progress":
          return "Picked Up";
        case "Picked Up":
          return "Dropped Off";
        case "Dropped Off":
          return "Picked Up";
        case "Picked Up":
          return "Dropped Off";
        case "Dropped Off":
          return "Completed";
        case "Completed":
          return;

        case "Canceled":
          return;
      }
      return "";
    },
    tConvert(time) {
      if (!time) return "Time not selected";
      // Check correct time format and split into components
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },
    getRepeatString(event, id) {
      for (let i = 0; i < this.repeatOptions.length; i++) {
        if (this.repeatOptions[i].value == id) {
          if (id == 3) {
            var weekDays = [];
            if (event.data.repeatSun) weekDays.push("Sun");
            if (event.data.repeatMon) weekDays.push("Mon");
            if (event.data.repeatTue) weekDays.push("Tue");
            if (event.data.repeatWed) weekDays.push("Wed");
            if (event.data.repeatThu) weekDays.push("Thu");
            if (event.data.repeatFri) weekDays.push("Fri");
            if (event.data.repeatSat) weekDays.push("Sat");
            return (
              this.repeatOptions[i].text + " (" + weekDays.join(", ") + ")"
            );
          }
          return this.repeatOptions[i].text;
        }
      }
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    // timeStringToDateTime(timeString) {
    //   var cFormat = "{y}-{m}-{d}";
    //   var vals = timeString.split("-");
    //   var formatSplit = cFormat.split("-");
    //   var m = formatSplit.indexOf("{m}");
    //   var d = formatSplit.indexOf("{d}");
    //   var y = formatSplit.indexOf("{y}");
    //   // console.log("month", vals[m]);
    //   // console.log("day", vals[d]);
    //   // console.log("year", vals[y]);

    //   return DateTime.local(
    //     parseInt(vals[y]),
    //     parseInt(vals[m]),
    //     parseInt(vals[d]),
    // 0,
    // 0,
    // 0
    //   );
    // },
    updateRange({ start, end }) {
      // console.log("updateRange");
      // console.log(start, end);
      const events = [];

      // const min = new Date(`${start.date}T00:00:00`);
      // const max = new Date(`${end.date}T23:59:59`);
      var startDate = DateTime.local(start.year, start.month, start.day);
      var endDate = DateTime.local(end.year, end.month, end.day);
      // console.log(startDate, endDate);

      for (let i = 0; i < this.orders.length; i++) {
        // console.log(this.orders[i]);

        if (
          this.patient &&
          (!this.orders[i].patient || this.orders[i].patient.id != this.patient)
        )
          continue;

        if (
          this.customer &&
          (!this.orders[i].customer ||
            this.orders[i].customer.id != this.customer)
        )
          continue;
        // if (
        //   this.driver &&
        //   (!this.orders[i].driver || this.orders[i].driver.id != this.driver)
        // )
        //   continue;
        // var driverFound = false;
        // if (this.driver) {
        //   for (let d = 0; d < this.orders[i].sub_orders.length; d++) {
        //     if (!this.orders[i].sub_orders[d].driver) continue;
        //     if (this.orders[i].sub_orders[d].driver.id == this.driver) {
        //       driverFound = true;
        //       break;
        //     }
        //   }
        //   if (!driverFound) continue;
        // }

        if (
          !this.orders[i].sub_orders ||
          !Array.isArray(this.orders[i].sub_orders)
        )
          continue;
        // console.log("processOrder");

        var ordersDetailed = this.orders[i].sub_orders;
        // this.orders[i].data.actual.concat(
        //   this.orders[i].data.future
        // );
        for (let d = 0; d < ordersDetailed.length; d++) {
          //TODO: проверка, отсечения лишних данных

          if (this.orderTypeSelected == 1) {
            if (ordersDetailed[d].status == "Completed") continue;
            if (ordersDetailed[d].status == "Canceled") continue;
          }
          if (this.onlyMyOrders) {
            if (this.orders[i].ordered_by.id != this.user.id) {
              continue;
            }
          }

          if (this.driver) {
            if (this.driver == -1 && ordersDetailed[d].driver) continue;
            if (this.driver != -1 && !ordersDetailed[d].driver) continue;
            if (this.driver != -1 && ordersDetailed[d].driver.id != this.driver)
              continue;
          }

          if (
            ordersDetailed[d].status &&
            ordersDetailed[d].status == "Canceled" &&
            ordersDetailed[d].status == "Completed"
          )
            continue;

          var endDatetime = new Date(
            // `${ordersDetailed[d].date}T${this.orders[i].timeStart}:00`
            `${ordersDetailed[d].date}T${ordersDetailed[d].time}:00`
          );
          // console.log(endDatetime);
          endDatetime.setTime(endDatetime.getTime() + 60 * 60 * 1000);
          // console.log(endDatetime);

          var dataMix = ordersDetailed[d];
          dataMix.distance = this.orders[i].distance;
          dataMix.dateStart = this.orders[i].dateStart;
          dataMix.timeStart = ordersDetailed.time;
          dataMix.dateEnd = this.orders[i].dateEnd;
          dataMix.patient = this.orders[i].patient;
          dataMix.pickupLocation = this.orders[i].pickupLocation;
          dataMix.destination = this.orders[i].destination;
          dataMix.customer = this.orders[i].customer;
          dataMix.ordered_by = this.orders[i].ordered_by;
          dataMix.created_at = this.orders[i].created_at;
          dataMix.priceDriver = ordersDetailed[d].priceDriver;
          dataMix.price = ordersDetailed[d].price;
          dataMix.order = this.orders[i].id;
          dataMix.subOrder = ordersDetailed[d].id;

          events.push({
            popupName: `#${this.orders[i].id}`,
            name: `#${this.orders[i].id}. ${
              this.orders[i].patient
                ? this.orders[i].patient.firstName +
                  " " +
                  this.orders[i].patient.lastName
                : "No Passenger"
            }
            `,
            start: new Date(
              `${ordersDetailed[d].date}T${ordersDetailed[d].time}:00`
            ),
            startString: `${ordersDetailed[d].date}T${ordersDetailed[d].time}:00`,
            end: endDatetime,
            color: ordersDetailed[d].driver
              ? ordersDetailed[d].driver.color
                ? ordersDetailed[d].driver.color
                : "indigo"
              : "black",
            data: dataMix,
            startRaw: ordersDetailed[d].date,
            status: ordersDetailed[d].status,
            editStatus: false,
            //TODO: добавить запрет на редактирование статуса
            timed: true,
          });
        }
      }

      // const days = (max.getTime() - min.getTime()) / 86400000;
      // const eventCount = this.rnd(days, days + 20);

      // for (let i = 0; i < eventCount; i++) {
      //   const allDay = this.rnd(0, 3) === 0;
      //   const firstTimestamp = this.rnd(min.getTime(), max.getTime());
      //   const first = new Date(firstTimestamp - (firstTimestamp % 900000));
      //   const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
      //   const second = new Date(first.getTime() + secondTimestamp);

      //   events.push({
      //     name: this.names[this.rnd(0, this.names.length - 1)],
      //     start: this.formatDate(first, !allDay),
      //     end: this.formatDate(second, !allDay),
      //     color: this.colors[this.rnd(0, this.colors.length - 1)],
      //   });
      // }
      // console.log("events", events);
      this.start = start;
      this.end = end;
      this.events = events;
    },
    nth(d) {
      return d > 3 && d < 21
        ? "th"
        : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    formatDate(a, withTime) {
      return withTime
        ? `${a.getFullYear()}-${
            a.getMonth() + 1
          }-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
        : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
    },
  },
};
</script>

<style scoped>
.selected-event-text {
  margin: 0px;
  padding: 0 15px;
  display: flex;
  flex-wrap: wrap;
}

.selected-event-text__title {
  min-width: 200px;
}
</style>
