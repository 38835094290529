export function monthDiff(start, end) {
  var tempDate = new Date(start);
  var monthCount = 0;
  while (
    tempDate.getMonth() + '' + tempDate.getFullYear() !=
    end.getMonth() + '' + end.getFullYear()
  ) {
    monthCount++;
    tempDate.setMonth(tempDate.getMonth() - 1);
  }
  return monthCount + 1;
}

export function convertTime12to24(time12h) {
  const [time, modifier] = time12h.split(" ");

  let [hours, minutes] = time.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
}
