<template>
  <div class="order__body">
    <v-form ref="form" v-model="valid" lazy-validation>
      <div class="order__container">
        <v-col cols="6" sm="12" md="6" v-if="isAdmin || isDriver">
          <v-select
            :items="statusOptions"
            hide-details="auto"
            :rules="requiredRules"
            v-model="order.status"
            label="Status"
            outlined
          ></v-select>
        </v-col>
        <div
          class="d-flex justify-space-around order__block flex-wrap"
          style="width: 100%"
          v-if="isAdmin || isClientUser || isClientAdmin"
        >
          <div
            v-if="isClientAdmin || isClientUser"
            class="d-flex align-center text-center"
            style="min-height: 50px"
          >
            <p class="font-weight-black mb-0">
              Appointment Date:
              {{
                new Date(
                  `${editedItem.date}T${editedItem.time}:00`
                ).toLocaleDateString("en-US")
              }}
            </p>
          </div>
          <div
            class="d-flex flex-column custom-error-handler-top-item ml-1 mr-1"
          >
            <v-dialog
              ref="dialog1"
              v-model="timeModal"
              :return-value.sync="timePicker"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formattedTimePicker"
                  label="Appointment Start"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker v-if="timeModal" v-model="timePicker" full-width>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="timeModal = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog1.save(timePicker)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
            <v-text-field
              v-model="timePicker"
              hide-details="auto"
              :rules="requiredTimeRules"
              class="custom-error-handler-input"
              style="top: 10px"
              disabled
            ></v-text-field>
          </div>
        </div>
        <!--  -->
        <div class="order__pickers" v-if="isAdmin || isDriver">
          <div
            class="d-flex flex-column custom-error-handler-top-item ml-1 mr-1"
            v-if="order.status == 'Confirmed'"
          >
            <!-- Time -->
            <!-- <v-time-picker v-model="timePicker"></v-time-picker> -->
            <v-dialog
              ref="dialog2"
              v-model="pickupTimeModal"
              :return-value.sync="pickupTimePicker"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formattedPickupTimePicker"
                  label="Pickup Time"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="pickupTimeModal"
                v-model="pickupTimePicker"
                full-width
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="pickupTimeModal = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog2.save(pickupTimePicker)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
            <v-text-field
              v-model="pickupTimePicker"
              hide-details="auto"
              :rules="requiredTimeRules"
              class="custom-error-handler-input"
              style="top: 10px"
              disabled
            ></v-text-field>
          </div>
        </div>

        <div
          class="d-flex justify-around order__block flex-wrap"
          v-if="isAdmin || isClientUser || isClientAdmin"
        >
          <!-- <div class="order__repeat">
                <v-select
                  :items="repeatOptions"
                  v-model="order.repeat"
                  label="Repeat"
                  outlined
                ></v-select>
              </div> -->
          <div class="order__trip">
            <v-btn-toggle v-model="order.roundTrip" mandatory>
              <v-btn>One Way</v-btn>
              <v-btn>Round Trip</v-btn>
            </v-btn-toggle>
          </div>
        </div>

        <div class="d-flex justify-around flex-wrap mt-4" v-if="isAdmin">
          <div class="field-input field-input-number">
            Driver Price
            <input
              type="number"
              min="0"
              placeholder="Driver Price"
              v-model="order.priceDriver"
            />
          </div>
          <div class="field-input field-input-number">
            Client Price
            <input
              type="number"
              min="0"
              placeholder="Client Price"
              v-model="order.price"
            />
          </div>
        </div>

        <div v-if="order.repeat == 3">
          <v-row class="light--text">
            <v-col>Sun</v-col>
            <v-col>Mon</v-col>
            <v-col>Tue</v-col>
            <v-col>Wed</v-col>
            <v-col>Thu</v-col>
            <v-col>Fri</v-col>
            <v-col>Sat</v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox value v-model="order.repeatSun"></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox value v-model="order.repeatMon"></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox value v-model="order.repeatTue"></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox value v-model="order.repeatWed"></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox value v-model="order.repeatThu"></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox value v-model="order.repeatFri"></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox value v-model="order.repeatSat"></v-checkbox>
            </v-col>
          </v-row>
        </div>
        <!-- {{ datePicker }}
            {{ timePicker }} -->
        <div class="order__notes">
          <v-text-field
            outlined
            label="Cancellation Reason"
            v-model="order.cancelReason"
          ></v-text-field>
        </div>
        <div class="order__notes">
          <v-textarea
            outlined
            :readonly="isDriver"
            label="Accommodation & Notes"
            v-model="order.note"
          ></v-textarea>
        </div>

        <!-- <div class="order__btn">
                <div class="d-flex justify-space-around">
                  <v-btn class="ml-2 mr-2" @click="dialog = false"
                    >Cancel</v-btn
                  >
                  <v-btn color="primary" class="ml-2 mr-2">Place Order</v-btn>
                </div>
            </div>-->
        <!-- {{order.driver}} -->
        <v-col cols="12" sm="12" md="12" v-if="isAdmin">
          <v-select
            :items="
              !drivers
                ? [
                    {
                      text: 'None',
                      value: null,
                    },
                  ]
                : [
                    {
                      text: 'None',
                      value: null,
                    },
                  ].concat(
                    drivers.map((item) => {
                      return {
                        text: item.firstName + ' ' + item.lastName,
                        value: parseInt(item.id),
                      };
                    })
                  )
            "
            v-model="order.driver"
            label="Driver"
            outlined
          ></v-select>
          <div
              v-if="isAdmin && driverOverlapingDates && driverOverlapingDates.length > 0"
              style="color: red"
          >
            {{ driverOverlapingDates.join(", ") }}
          </div>
        </v-col>
      </div>
    </v-form>

    <v-card-actions class="order-popup-actions">
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
      <v-btn color="blue darken-1" text @click="save()">Save</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import AddEditPatientComponent from "@/components/data/AddEditPatientComponent";
import { DateTime } from "luxon";

export default {
  props: {
    cancelCallback: {
      type: Function,
    },
    saveCallback: {
      type: Function,
    },
    editedItem: {
      type: Object,
    },
  },
  name: "AddEditSubOrderComponent",
  components: {
    // AddEditPatientComponent,
  },
  data: () => ({
    datePicker: null,
    timePicker: null,
    pickupTimePicker: null,
    pick: null,
    items: ["Other", "Home"],
    apiKey: "AIzaSyBFwsSWdZdUYzlomq7JyucZdEx8hTEjRnU",
    comments: "",
    order: {},
    //
    timeModal: false,
    pickupTimeModal: false,
    //
    defaultOrder: {
      // repeat: -1,
      // roundTrip: 1,
      // repeatSun: false,
      // repeatMon: true,
      // repeatTue: true,
      // repeatWed: true,
      // repeatThu: true,
      // repeatFri: true,
      // repeatSat: false,
    },
    //
    dialogDestination: false,
    newDestination: {},
    //
    editedPatient: {},
    defaultPatient: {
      locations: [],
      contacts: [],
    },
    dialogPatient: false,
    editedIndex: -1,
    //
    statusOptions: [
      {
        text: "Pending",
        value: "Pending",
      },
      {
        text: "Confirmed",
        value: "Confirmed",
      },
      {
        text: "In Route",
        value: "In Route",
      },
      {
        text: "Delivered",
        value: "Delivered",
      },
      {
        text: "Return in Route",
        value: "Return in Route",
      },
      {
        text: "Completed",
        value: "Completed",
      },
      {
        text: "Canceled",
        value: "Canceled",
      },
      // {
      //   text: "Every month",
      //   value: 4,
      // },
    ],
    // repeatOptions: [
    //   {
    //     text: "None",
    //     value: -1,
    //   },
    //   {
    //     text: "Every day",
    //     value: 1,
    //   },
    //   {
    //     text: "Every weekday",
    //     value: 2,
    //   },
    //   {
    //     text: "Every week",
    //     value: 3,
    //   },
    //   // {
    //   //   text: "Every month",
    //   //   value: 4,
    //   // },
    // ],
    //
    drivers: undefined,
    driverOverlapingDates: undefined,
    valid: true,
    validDestination: true,
    //  hide-details="auto"
    // :rules="requiredRules"
    requiredRules: [(v) => !!v || "Field is required"],
    requiredDateRules: [(v) => !!v || "Date is required"],
    requiredTimeRules: [(v) => !!v || "Time is required"],
  }),
  apollo: {
    Drivers: {
      prefetch: true,
      query: require("@/graphql/Drivers.gql"),
      fetchPolicy: "cache-and-network",
      update: (data) => data,
      result(data) {
        if (!data.data) return;
        // console.log("drivers", data);

        this.drivers = data.data.users;

        this.drivers = this.drivers.filter((item) => item.role);
        this.drivers = this.drivers.filter(
          (item) => item.role.name === "Driver" && item.activated
        );

        this.drivers.sort(function (a, b) {
          if (a.firstName < b.firstName) {
            return -1;
          }
          if (a.firstName > b.firstName) {
            return 1;
          }
          return 0;
        });
      },
    },
  },
  computed: {
    formattedTimePicker() {
      return !this.timePicker ? "" : this.tConvert(this.timePicker);
    },
    formattedPickupTimePicker() {
      return !this.pickupTimePicker ? "" : this.tConvert(this.pickupTimePicker);
    },
    yesterday() {
      return new Date(new Date().setDate(new Date().getDate() - 1));
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Passenger" : "Edit Passenger";
    },

    ...mapGetters([
      "user",
      "strapi",
      "isAdmin",
      "isClientAdmin",
      "isClientUser",
      "isDriver",
    ]),
  },
  created() {
    this.initialize();
  },
  watch: {
    editedItem(value) {
      if (value !== null) this.initialize();
    },
    'order.driver'() {

      // console.log("editedItem Driver changed", this.order.driver);
      if (!this.drivers) return;

      // this.order.dateStart = this.datePicker[0];
      // this.order.dateEnd = this.datePicker[1]
      //     ? this.datePicker[1]
      //     : this.datePicker[0];
      //
      var driver = this.drivers.find((item) => item.id == this.order.driver);
      this.driverOverlapingDates = [];
      if (driver && driver.sub_orders) {
        driver.sub_orders.forEach((element) => {

          if (
              element.status != "Completed" &&
              element.status != "Canceled" &&
              element.date === this.order.date
              // newDates.some((item) => item == element.date)
          ) {
            this.driverOverlapingDates.push(
                element.date +
                " " +
                this.tConvert(element.time) +
                " (#" +
                (element.order ? element.order.id : "-") +
                ")"
            );
          }
        });
      }
    }
  },
  mounted() {
  },
  // watch: {
  //   editedItem() {
  //     console.log("watch", this.editedItem);
  //   },
  // },
  methods: {
    // getPickupLocations(data) {
    //   if (!this.order.patient) return [];
    //   var index = data.patients.findIndex(
    //     (i) => parseInt(i.id) == this.order.patient
    //   );
    //   if (
    //     !data.patients[index] ||
    //     !Array.isArray(data.patients[index].locations)
    //   )
    //     return [];
    //   return data.patients[index].locations.map((item) => {
    //     return {
    //       text:
    //         item.name +
    //         " (" +
    //         item.address +
    //         (item.note ? ", " + item.note : "") +
    //         ")",
    //       value: {
    //         name: item.name,
    //         address: item.address,
    //         note: item.note,
    //       },
    //     };
    //   });
    // },
    tConvert(time) {
      if (!time) return "Time not selected";
      // Check correct time format and split into components
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      // console.log(time);
      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },
    initialize() {
      // Object.assign(this.editedPatient, this.defaultPatient);
      // this.order = this.lodash.cloneDeep(this.defaultOrder);
      // this.editedPatient = this.lodash.cloneDeep(this.defaultPatient);

      // if (
      //   this.user.role.name == "ClientUser" ||
      //   this.user.role.name == "ClientAdmin"
      // ) {
      //   this.defaultOrder.customer = this.user.customer
      //     ? parseInt(this.user.customer.id)
      //     : undefined;
      // }

      // if (this.editedItem) {
      // Object.assign(this.order, this.editedItem);

      this.order = this.lodash.cloneDeep(this.editedItem);
      // this.order.patient = this.order.patient ? this.order.patient.id : null;
      // this.order.customer = this.order.customer
      //   ? parseInt(this.order.customer.id)
      //   : null;
      this.order.driver = this.order.driver
        ? parseInt(this.order.driver.id)
        : null;
      // if (this.order.dateStart && this.order.dateEnd)
      //   this.datePicker = [this.order.dateStart, this.order.dateEnd];
      // else this.datePicker = undefined;
      this.timePicker = this.order.time;
      this.pickupTimePicker = this.order.pickupTime;
      // } else {
      // this.order = this.lodash.cloneDeep(this.defaultOrder);
      //   this.datePicker = undefined;
      // this.timePicker = undefined;
      // }
    },
    timeStringToDateTime(timeString) {
      var cFormat = "{y}-{m}-{d}";
      var vals = timeString.split("-");
      var formatSplit = cFormat.split("-");
      var m = formatSplit.indexOf("{m}");
      var d = formatSplit.indexOf("{d}");
      var y = formatSplit.indexOf("{y}");
      // console.log("month", vals[m]);
      // console.log("day", vals[d]);
      // console.log("year", vals[y]);

      return DateTime.local(
        parseInt(vals[y]),
        parseInt(vals[m]),
        parseInt(vals[d]),
        0,
        0,
        0
      );
    },
    // async processDates(order) {
    //   console.log('processDates', order)
    //   // console.log("this.order.data", this.order.data);
    //   // console.log("this.order.dateStart", this.order.dateStart);

    //   // var data = this.order.data ? this.order.data : { actual: [], future: [] };
    //   // data.future = [];

    //   var data = order.sub_orders ? order.sub_orders : [];
    //   console.log("data", data);

    //   // var actualDates = data.actual.map((item) => item.date);
    //   var actualDates = data.filter(
    //     (item) => item.edited || !item.status || item.status != "Pending"
    //   ); //.map((item) => item.date);
    //   var newDates = [];

    //   console.log("actualDates", actualDates);

    //   if (!order.dateStart || !order.repeat) {
    //     console.error("no dateStart or repeat optio");
    //     return;
    //   }

    //   // console.log(
    //   //   new Date().toISOString(),
    //   //   new Date().toISOString().substring(0, 10)
    //   // );

    //   var today = DateTime.local();
    //   today = DateTime.local(today.year, today.month, today.day);

    //   var startDate = this.timeStringToDateTime(order.dateStart);
    //   var endDate = this.timeStringToDateTime(order.dateEnd);
    //   // console.log("today Luxon", today, today.toISODate());
    //   // console.log("startDate Luxon", startDate, startDate.toISODate());
    //   // console.log("endDate Luxon", endDate, endDate.toISODate());

    //   var iterator = this.timeStringToDateTime(order.dateStart);

    //   // console.log("diff", today.diff(startDate, "days").toObject().days);

    //   // if (today.diff(startDate, "days").toObject().days > 0) data.future = [];
    //   // else {
    //   switch (order.repeat) {
    //     case -1: //None
    //       // console.log(today.diff(startDate, "days").toObject().days);
    //       if (
    //         !actualDates.some((item) => item.date == startDate.toISODate()) &&
    //         today.diff(startDate, "days").toObject().days <= 0
    //       )
    //         // data.future
    //         newDates = [startDate.toISODate()];
    //       break;
    //     case 1: //Every day
    //       while (iterator.diff(endDate, "days").toObject().days <= 0) {
    //         if (
    //           !actualDates.some((item) => item.date == iterator.toISODate()) &&
    //           today.diff(iterator, "days").toObject().days <= 0
    //         )
    //           // data.future.
    //           newDates.push(iterator.toISODate());
    //         iterator = iterator.plus({ days: 1 });
    //       }
    //       break;
    //     case 2: //Every weekday
    //       while (iterator.diff(endDate, "days").toObject().days <= 0) {
    //         if (
    //           iterator.weekday < 6 &&
    //           !actualDates.some((item) => item.date == iterator.toISODate()) &&
    //           today.diff(iterator, "days").toObject().days <= 0
    //         )
    //           // data.future
    //           newDates.push(iterator.toISODate());
    //         iterator = iterator.plus({ days: 1 });
    //       }
    //       break;
    //     case 3: //Every week
    //       var daysOfWeekSelected = [];
    //       if (order.repeatMon) daysOfWeekSelected.push(1);
    //       if (order.repeatTue) daysOfWeekSelected.push(2);
    //       if (order.repeatWed) daysOfWeekSelected.push(3);
    //       if (order.repeatThu) daysOfWeekSelected.push(4);
    //       if (order.repeatFri) daysOfWeekSelected.push(5);
    //       if (order.repeatSat) daysOfWeekSelected.push(6);
    //       if (order.repeatSun) daysOfWeekSelected.push(7);

    //       if (daysOfWeekSelected.length > 0) {
    //         while (iterator.diff(endDate, "days").toObject().days <= 0) {
    //           if (
    //             !actualDates.some(
    //               (item) => item.date == iterator.toISODate()
    //             ) &&
    //             today.diff(iterator, "days").toObject().days <= 0 &&
    //             daysOfWeekSelected.includes(iterator.weekday)
    //           )
    //             // data.future
    //             newDates.push(iterator.toISODate());
    //           iterator = iterator.plus({ days: 1 });
    //         }
    //       }
    //       break;
    //     // case 4: //Every month
    //     //   while (iterator.diff(endDate, "days").toObject().days <= 0) {
    //     //     if (
    //     //       !actualDates.includes(iterator.toISODate()) &&
    //     //       today.diff(iterator, "days").toObject().days <= 0
    //     //     )
    //     //       data.future.push(iterator.toISODate());
    //     //     iterator = iterator.plus({ months: 1 });
    //     //   }
    //     //   break;
    //   }
    //   // }

    //   // console.log(
    //   //   data.future.map((item) => {
    //   //     return { status: "Pending", date: item };
    //   //   })
    //   // );

    //   // await this.strapi.createEntry("subOrders", this.order);

    //   newDates = newDates.map((item) => {
    //     return {
    //       status: "Pending",
    //       date: item,
    //       driver: order.driver ? order.driver.id : undefined,
    //       time: order.time,
    //       roundTrip: order.roundTrip,
    //       order: order.id,
    //       note: order.note,
    //       price: order.price,
    //       priceDriver: order.priceDriver,
    //     };
    //   });

    //   for (let i = 0; i < data.length; i++) {
    //     if (!actualDates.some((item) => item.id == data[i].id)) {
    //       await this.strapi.deleteEntry("sub-orders", data[i].id);
    //     }
    //   }

    //   order.sub_orders = actualDates;
    //   for (let i = 0; i <script newDates.length; i++) {
    //     var newSubOrder = await this.strapi.createEntry(
    //       "sub-orders",
    //       newDates[i]
    //     );
    //     order.sub_orders.push(newSubOrder);
    //   }

    //   console.log("newDates", newDates);

    //   order.sub_orders = order.sub_orders.map((item) => parseInt(item.id));
    //   await this.strapi.updateEntry("orders", parseInt(order.id), order);
    //   //
    // },
    //
    async save() {
      if (this.isSaving) return;
      this.isSaving = true;
      // console.log("save");
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        if (this.cancelCallback) {
          this.cancelCallback();
        }

        // this.order.dateStart = this.datePicker[0];
        // this.order.dateEnd = this.datePicker[1]
        //   ? this.datePicker[1]
        //   : this.datePicker[0];
        // if (
        //   new Date(this.order.dateStart).getTime() >
        //   new Date(this.order.dateEnd).getTime()
        // ) {
        //   var tempDate = this.order.dateStart;
        //   this.order.dateStart = this.order.dateEnd;
        //   this.order.dateEnd = tempDate;
        // }

        this.order.time = this.timePicker;
        if (this.order.status === "Confirmed")
          this.order.pickupTime = this.pickupTimePicker;
        this.order.edited = true;

        var time = DateTime.local().toJSON().slice(11, 16);
        this.order.timeNotification = this.tConvert(time);

        // console.log(this.order.timeNotification);

        // await this.processDates();

        // if (
        //   this.user.role.name == "ClientUser" ||
        //   this.user.role.name == "ClientAdmin"
        // ) {
        //   this.order.customer = this.user.customer
        //     ? parseInt(this.user.customer.id)
        //     : undefined;
        // }

        // console.log(this.order);

        if (this.order.id) {
          var updatedItem = await this.strapi.updateEntry(
            "sub-orders",
            parseInt(this.order.id),
            this.order
          );

          // console.log(updatedItem);

          if (this.saveCallback) {
            this.saveCallback(updatedItem);
          }

          // await this.processDates(updatedItem);
        }

        // else {
        //   var newItem = await this.strapi.createEntry("orders", this.order);

        //   // await this.processDates(newItem);

        //   if (this.saveCallback) {
        //     this.saveCallback(newItem);
        //   }
        // }

        this.close();
      }
    },
    close() {
      this.initialize();

      if (this.cancelCallback) {
        this.cancelCallback();
      }
      //   this.order = this.lodash.cloneDeep(this.defaultOrder);
      //   this.datePicker = undefined;
      //   this.timePicker = undefined;
    },
    // closePatientPopup() {
    //   this.dialogPatient = false;
    //   this.$nextTick(() => {
    //     // Object.assign(this.editedPatient, this.defaultItem);
    //     this.editedPatient = this.lodash.cloneDeep(this.defaultPatient);
    //     this.editedIndex = -1;
    //   });
    // },
    // createPatient() {
    //   // Object.assign(this.editedPatient, this.defaultItem);

    //   this.editedPatient = this.lodash.cloneDeep(this.defaultPatient);
    //   this.editedIndex = -1;
    //   this.dialogPatient = true;
    // },
    // editPatient(patientID, data) {
    //   // console.log(item);
    //   // this.editedIndex = item.id;
    //   this.editedIndex = data.patients.findIndex(
    //     (item) => item.id == patientID
    //   );
    //   var patient = data.patients[this.editedIndex];
    //   // Object.assign(this.editedPatient, item);
    //   this.editedPatient = this.lodash.cloneDeep(patient);
    //   this.editedPatient.customer = patient.customer
    //     ? parseInt(patient.customer.id)
    //     : undefined;

    //   // console.log(this.editedPatient);

    //   this.dialogPatient = true;
    //   // this.$nextTick(() => {
    //   //   this.$refs.googleSearch.textValue = item.address;
    //   // });
    // },

    // async savePatient(data) {
    //   // console.log("this.editedIndex", this.editedIndex);
    //   this.$refs.addEditPatientComponent.$refs.form.validate();
    //   if (this.$refs.addEditPatientComponent.$refs.form.validate(true)) {
    //     if (this.editedIndex > -1) {
    //       // console.log(this.order);
    //       var updatedItem = await this.strapi.updateEntry(
    //         "patients",
    //         parseInt(this.editedPatient.id),
    //         this.editedPatient
    //       );
    //       var index = data.patients.findIndex(
    //         (item) => parseInt(item.id) == parseInt(updatedItem.id)
    //       );
    //       // console.log(index, updatedItem);
    //       // data.patients[index]= updatedItem;
    //       Object.assign(data.patients[index], updatedItem);
    //       this.order.patient = parseInt(updatedItem.id);
    //       // console.log(this.order);
    //     } else {
    //       // console.log(this.user.role.name);
    //       // console.log(
    //       //   this.user.role.name == "ClientUser" ||
    //       //     this.user.role.name == "ClientAdmin"
    //       // );

    //       if (
    //         this.user.role.name == "ClientUser" ||
    //         this.user.role.name == "ClientAdmin"
    //       ) {
    //         this.editedPatient.customer = this.user.customer
    //           ? parseInt(this.user.customer.id)
    //           : undefined;
    //       }
    //       var newItem = await this.strapi.createEntry(
    //         "patients",
    //         this.editedPatient
    //       );
    //       this.order.patient = parseInt(newItem.id);
    //       data.patients.push(newItem);
    //     }
    //     this.closePatientPopup();
    //   }
    // },
    // async saveDestination(data) {
    //   this.$refs.formDestination.validate();
    //   if (this.$refs.formDestination.validate(true)) {
    //     if (
    //       this.user.role.name == "ClientUser" ||
    //       this.user.role.name == "ClientAdmin"
    //     ) {
    //       this.newDestination.customer = this.user.customer
    //         ? parseInt(this.user.customer.id)
    //         : undefined;
    //     }
    //     var newItem = await this.strapi.createEntry(
    //       "destinations",
    //       this.newDestination
    //     );
    //     this.order.destination = {
    //       alias: newItem.alias,
    //       address: newItem.address,
    //       comment: newItem.comment,
    //     };
    //     data.destinations.push(this.order.destination);
    //     this.closeDestination();
    //   }
    // },
    // closeDestination() {
    //   this.newDestination = {};
    //   this.dialogDestination = false;
    // },
  },
};
</script>

<style lang="scss" scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

//
.field-input-number {
  border: 1px solid black;
  padding: 10px;
  max-width: 150px;
  margin: 20px;
}

//
//
//
//
//
//

.order {
  //   width: 100%;
  //   padding: 65px 25px 65px 25px;

  //   display: flex;
  //   align-items: center;
  //   justify-content: center;

  &__patient {
    @media screen and (max-width: 520px) {
      flex-direction: column;
    }
  }

  &__notes {
    width: 100%;
    margin-top: 50px;

    textarea {
      width: 100%;
    }
  }
  &__repeat {
    margin: 0px 20px;
    margin-top: 50px;
  }
  &__trip {
    margin: 0px 20px;
    margin-top: 54px;
  }

  &__pickers {
    width: 100%;

    padding: 0 12px;

    display: flex;
    align-items: stretch;
    justify-content: center;

    flex-direction: row;

    @media screen and (max-width: 850px) {
      flex-direction: column;
    }
  }

  &__container {
    width: 100%;
    max-width: 700px;

    margin: 0px auto;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
  }

  &__body {
    width: 100%;
    max-width: 1300px;

    padding: 25px;
    margin: 0px auto;

    background-color: #ffffff;

    transition: all 0.2s linear;

    @media screen and (max-width: 470px) {
      padding: 40px 30px;
    }

    -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
  }

  &__pat-input {
    // padding-top: 28px;

    @media screen and (max-width: 520px) {
      padding-top: 0;
      width: 100%;
    }
  }

  &__buttons {
    width: 100%;
    max-width: 120px;

    display: flex;
    flex-direction: column;

    margin-left: 20px;

    min-height: 80px;
    display: flex;
    flex-flow: column;
    justify-content: space-around;

    button {
      &:last-child {
        margin-bottom: 1px;
      }
    }

    @media screen and (max-width: 520px) {
      max-width: 100%;
      margin-left: 0;
    }
  }

  &__destination {
    display: flex;
    align-items: center;

    width: 100%;

    padding: 0 12px;

    margin-bottom: 15px;
    padding-top: 28px;
    .v-input {
      // padding-top: 28px;
    }

    button {
      margin-left: 20px;
    }
  }
}

.destination__body {
  width: 100%;
  max-width: 500px;

  padding: 25px;
  background: #ffffff;
}

.v-picker--date {
  margin-right: 30px !important;

  @media screen and (max-width: 850px) {
    margin-right: 0 !important;
    margin-bottom: 30px !important;
  }
}

.v-picker {
  flex: 0 1 50% !important;

  @media screen and (max-width: 850px) {
    flex: 0 1 100% !important;
  }
}

// .popbtn {
//   border-radius: 25px;
// }

.order__pickers {
  .v-picker {
    -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
  }
}
</style>
