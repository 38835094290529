import { render, staticRenderFns } from "./AddEditOrderComponent.vue?vue&type=template&id=c48ce750&scoped=true"
import script from "./AddEditOrderComponent.vue?vue&type=script&lang=js"
export * from "./AddEditOrderComponent.vue?vue&type=script&lang=js"
import style0 from "./AddEditOrderComponent.vue?vue&type=style&index=0&id=c48ce750&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c48ce750",
  null
  
)

export default component.exports